<template>
  <v-card class="mx-auto" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <span
            class="text-h6 ml-1"
            v-text="
              `${$route.meta.title} ${
                loading
                  ? ''
                  : this.data && this.data.length > 0
                  ? `(${this.data.length})`
                  : ''
              }`
            "
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" xs="12" md="3">
          <v-select
            label="Estado"
            v-model="search.dispatched"
            :items="dispatched_options"
            item-value="id"
            :item-text="(item) => item.value"
            dense
            :disabled="data.length > 0"
          />
        </v-col>
        <v-col v-if="login.role_id != 13" cols="12" xs="12" md="3">
          <v-select
            label="Tipo"
            v-model="search.type"
            :items="type_options"
            item-value="id"
            :item-text="(item) => item.value"
            dense
            @change="search.enrollment = ''"
            :disabled="data.length > 0"
          />
        </v-col>
        <v-col v-if="search.type == 1" cols="12" xs="12" md="2">
          <v-text-field
            v-model="search.enrollment"
            label="ID SM"
            v-on:keyup.enter="servicePharmacySearch"
            hide-details
            dense
            :disabled="data.length > 0"
          />
        </v-col>
        <v-col
          v-if="search.dispatched == 1 && search.type == 0"
          cols="12"
          xs="12"
          md="2"
        >
          <DatePicker
            label="Fecha inicio"
            :model.sync="search.start_date"
            :disabled="data.length > 0"
          />
        </v-col>
        <v-col
          v-if="search.dispatched == 1 && search.type == 0"
          cols="12"
          xs="12"
          md="2"
        >
          <DatePicker
            label="Fecha final"
            :model.sync="search.end_date"
            :disabled="data.length > 0"
          />
        </v-col>
        <v-col cols="12">
          <v-btn
            v-if="data.length == 0"
            block
            small
            :loading="loading"
            color="primary"
            @click="servicePharmacySearch"
            :disabled="search.type == 1 && search.enrollment == ''"
          >
            Buscar
            <v-icon right v-text="'mdi-magnify'" />
          </v-btn>
          <v-btn v-else block small @click="data = []">
            Cambiar parametros
            <v-icon right v-text="'mdi-refresh'" />
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" v-if="alert_distpach">
          <v-alert type="info" v-model="alert_distpach" dismissible dense>
            <div class="text-center" v-text="`${alert_distpach_msg}`" />
          </v-alert>
        </v-col>
        <v-col cols="12" xs="12">
          <v-data-table
            :headers="headers"
            :items="data"
            :loading="loading"
            :items-per-page="15"
            dense
          >
            <template v-slot:item.action="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    fab
                    x-small
                    color="warning"
                    :href="url_pdf + '/carta/servicios/' + item.rs_service_id"
                    target="_blank"
                  >
                    <v-icon v-text="'mdi-download'" />
                  </v-btn>
                </template>
                <span v-text="'Ver receta'" />
              </v-tooltip>
              <v-tooltip
                left
                v-if="login.permissions.rs_pharmacies.update && !item.dispatch"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="ml-1"
                    v-on="on"
                    fab
                    x-small
                    color="primary"
                    @click="dispatchSubmit(item)"
                  >
                    <v-icon v-text="'mdi-check-all'" />
                  </v-btn>
                </template>
                <span v-text="'Surtir receta'" />
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  URL_PDF,
  msgConfirm,
  msgAlert,
  dateTimeNow,
} from "../../control";
import { index, storeUpdate } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import DatePicker from "../../components/DatePicker.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    DatePicker,
    FaqDlg,
  },
  data() {
    return {
      api: "rss/pharmacy/dispacher",
      login: this.$store.getters.getLogin,
      url_pdf: URL_PDF,
      loading: false,
      data: [],
      total_rows: 0,
      headers: [
        { text: "Folio", filterable: true, value: "folio" },
        { text: "ID SM", filterable: true, value: "enrollment" },
        { text: "Asegurado", filterable: true, value: "full_name" },
        { text: "Médico", filterable: false, value: "doctor" },
        { text: "F. carta", filterable: false, value: "letter_generated" },
        { text: "Observación", filterable: false, value: "caption" },
        { text: "Acción", value: "action", sortable: false },
      ],
      type_options: [
        { id: 0, value: "TODOS" },
        { id: 1, value: "ASEGURADO" },
      ],
      dispatched_options: [
        { id: 0, value: "PENDIENTES" },
        { id: 1, value: "TERMINADAS" },
      ],
      search: {
        enrollment: "",
        start_date: "",
        end_date: "",
        dispatched: 0,
        type: 1,
      },
      alert_distpach: false,
      alert_distpach_msg: null,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    servicePharmacySearch() {
      this.loading = true;
      index(this.api, this.login.token, [
        {
          name: "id",
          value: this.login.id,
        },
        {
          name: "enrollment",
          value: this.search.enrollment == "" ? null : this.search.enrollment,
        },
        {
          name: "start_date",
          value: this.search.start_date,
        },
        {
          name: "end_date",
          value: this.search.end_date,
        },
        {
          name: "dispatched",
          value: this.search.dispatched,
        },
      ]).then((res) => {
        this.data = res.data;
        this.loading = false;
      });
    },
    dispatchSubmit(item) {
      this.$swal
        .fire(
          msgConfirm(
            `¿Confirma surtir la receta ${item.folio} para el asegurado ${item.full_name}?`
          )
        )
        .then((res) => {
          if (res.isConfirmed) {
            this.loading = true;
            this.alert_distpach = false;
            this.alert_distpach_msg = null;

            storeUpdate(
              this.api,
              this.login.token,
              this.id,
              {
                id: item.id,
                dispatched_by_id: this.login.id,
                folio: item.folio,
              },
              this.login.id
            ).then((res) => {
              this.$swal.fire(
                msgAlert(res.success ? "success" : "error", res.message)
              );

              if (res.success) {
                this.alert_distpach = true;
                this.alert_distpach_msg = res.message;
                this.servicePharmacySearch();
              } else {
                console.log(res.data.message);
              }
              this.loading = false;
            });
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.search.start_date = dateTimeNow().substring(0, 8) + "01";
    this.search.end_date = dateTimeNow().substring(0, 10);
  },
};
</script>